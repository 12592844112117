const fit = el => {
	if (window.getComputedStyle(el).display !== 'block')
		return;

	if (el.clientWidth >= el.scrollWidth)
		return;

	const c = window.getComputedStyle(el).fontSize.replace('px', '')|0;
	el.style.fontSize = (c - 1) + 'px';
	fit(el);
};

export default function fitText (el) {
	if (typeof el === 'string')
		el = document.querySelectorAll(el);

	if (el.length)
		for (let i = 0, l = el.length; i < l; i++)
			fit(el[i]);
	else
		fit(el);
}