export default class Animate {

	// Properties
	// =========================================================================

	io = null;

	constructor () {
		const elems = document.getElementsByClassName('animate');

		this.io = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.intersectionRatio <= 0)
					return;

				entry.target.classList.remove('animate');
				this.io.unobserve(entry.target);
			});
		}, {
			rootMargin: '-50px 0px',
			threshold: 0.01
		});

		for (let i = 0, l = elems.length; i < l; ++i)
			this.io.observe(elems[i]);
	}

	deconstructor () {
		this.io.disconnect();
	}

}