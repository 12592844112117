import Lazy from 'ether-lazy';
import Animate from './Animate';
// import Transition from './Transition';
// import scroll from './helpers/scroll';
import fitText from './helpers/fitText';

const loaded = [];

const isIE = (
	window.navigator.userAgent.indexOf('MSIE ') > -1 ||
	window.navigator.userAgent.indexOf('Trident/') > -1
);

const load = (...i) => {
	Promise.all(i).then(
		f => f.map(f => loaded.push(new f.default()))
	).catch(e => {
		if (e.message.indexOf('Loading chunk') > -1 && !isIE)
			window.location.reload();
		else
			console.error(e); // eslint-disable-line no-console
	});
};

// const unload = () => {
// 	scroll.deconstructor();
// 	scroll.constructor();
//
// 	while (loaded.length) {
// 		const cls = loaded.pop();
// 		cls.deconstructor && cls.deconstructor();
// 	}
// };

let isInitialLoad = true;

const ready = () => {
	// if (!isIE) loaded.push(new Transition(ready, unload));

	loaded.push(new Lazy());
	loaded.push(new Animate());
	fitText('h1');

	if (document.getElementsByTagName('form').length > 0)
		load(import('./components/Contact'));

	switch (window.p) {
		case 'index':
			load(
				import('./components/HeroHome'),
				import('./components/StaggeredWorkDeck'),
			);
			break;
		case 'work':
			load(
				import('./components/WorkArchive'),
			);
			break;
		case 'post':
			load(
				import('./components/PostHero'),
			);
			break;
		case 'contact':
			load(
				import('./components/BgGraphic'),
				import('./components/Form'),
			);
			break;
		case 'studio':
			load(
				import('./components/SideScroll'),
			);
			break;
		case 'job':
			load(
				import('./components/Job'),
				import('./components/Form'),
			);
			break;
	}

	if (isInitialLoad) {
		isInitialLoad = false;
		document.removeEventListener('DOMContentLoaded', ready, false);
	} else {
		try {
			window.gtag('config', window.uaKey, { 'page_path': window.location.pathname });
		} catch (e) {} // eslint-disable-line
	}
};

document.addEventListener('DOMContentLoaded', ready, false);